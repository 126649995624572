import React from "react";
import { TreeSelect } from "antd";
import { where } from "@3ts/react-ant-crud";
import {
  ProductCategory,
  useGetProductCategoriesQuery,
} from "../../graphql/schema";

interface Props {
  value?: number;
  onChange?: (value: number) => void;
  disabled?: boolean;
  filter?: any;
}

const ProductCategoryTreeSelect = ({
  value,
  onChange,
  filter,
  disabled,
}: Props) => {
  const { data } = useGetProductCategoriesQuery({
    fetchPolicy: "no-cache",
    variables: {
      options: {
        ...where<ProductCategory>({
          parentId: null,
        }),
        itemsPerPage: 10000,
      },
    },
  });
  return (
    <TreeSelect
      showSearch
      treeNodeFilterProp="title"
      style={{ width: "100%" }}
      value={value ? `0-${value}` : undefined}
      onChange={(val: any) => {
        if (onChange) onChange(Number(val.split("-")[1]));
      }}
      treeData={
        data?.productCategories?.items?.map((item) => ({
          title: item.name,
          value: `${item.parentId ? 1 : 0}-${item.id}`,
          children: item.children?.map((child) => ({
            title: child.name,
            value: `${item.parentId ? 1 : 0}-${child.id}`,
          })),
        })) || []
      }
      disabled={disabled}
    />
  );
};

export default ProductCategoryTreeSelect;
