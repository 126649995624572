import { Col, Flex, Row, Skeleton, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import React from "react";
import dayjs from "dayjs";
import StatusName from "../../drawer/StatusName";
import { useGetCalendarPopoverAppointmentQuery } from "../../../../../graphql/schema";

const PopoverContent = ({ id }: { id: number }) => {
  const { data, loading, error } = useGetCalendarPopoverAppointmentQuery({
    variables: {
      id: id.toString() ?? Number.MIN_SAFE_INTEGER.toString(),
    },
    skip: !id,
  });

  if (loading) return <Skeleton active />;
  if (error || !data) return null;

  const {
    appointmentStatus,
    dateFrom,
    dateUntil,
    products,
    message,
    customers,
    internalNote,
  } = data.appointment;

  return (
    <Row
      style={{
        padding: 5,
        width: 200,
      }}
    >
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Typography.Text
              style={{
                fontSize: "12px",
                fontWeight: "lighter",
              }}
            >
              <StatusName status={appointmentStatus} /> (
              {dayjs(dateFrom).tz().format("HH:mm")} -{" "}
              {dayjs(dateUntil).tz().format("HH:mm")})
            </Typography.Text>
          </Col>
        </Row>
        <Row>
          {customers?.map((customer: any) => (
            <Col span={24}>
              <Flex>
                <UserOutlined
                  style={{
                    padding: 10,
                    fontSize: "20px",
                  }}
                />
                <Typography.Text
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {customer.firstName} {customer.lastName}
                </Typography.Text>
              </Flex>
            </Col>
          ))}
        </Row>
        {products?.length && (
          <Row>
            <Col span={24}>
              <Row>
                <Col>
                  <Typography.Text strong>Leistungen</Typography.Text>
                </Col>
              </Row>
              <Row>
                {products
                  ?.filter((product: any) => !product?.package?.id)
                  ?.map((product: any) => (
                    <Col span={24}>
                      <Typography.Text>{product.name}</Typography.Text>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        )}
        {message && (
          <Row>
            <Col span={24}>
              <Row>
                <Col>
                  <Typography.Text strong>Nachricht</Typography.Text>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Typography.Text>{message}</Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {internalNote && (
          <Row>
            <Col span={24}>
              <Row>
                <Col>
                  <Typography.Text strong>Interne Notiz</Typography.Text>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Typography.Text>{internalNote}</Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default PopoverContent;
