import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Flex, Form, FormInstance, Modal, Typography } from "antd";
import dayjs from "dayjs";
import { WarningOutlined } from "@ant-design/icons";
import { useCalendar } from "../calendar/CalendarContext";
import {
  Appointment,
  AppointmentStatus,
  useGetAppointmentQuery,
} from "../../../../graphql/schema";
import getProductsTotalDuration from "../../../../helper/common/getProductsTotalDuration";
import VacanciesErrorContent from "../drawer/VacanciesErrorContent";
import useValidateVacancies, {
  ValidateVacanciesProps,
  ValidateVacancyProps,
} from "../../../../helper/hooks/useValidateVacancies";

const { confirm } = Modal;

export interface AppointmentContextType {
  tab: "new" | "blocker";
  setTab: (tab: "new" | "blocker") => void;
  appointment?: Partial<Appointment> | undefined;
  loading: boolean;
  formValues: any;
  form: FormInstance | undefined;
  validateVacancies: (input: ValidateVacanciesProps) => Promise<void>;
  validateVacancy: (input: ValidateVacancyProps) => Promise<void>;
  refetch: () => Promise<void>;
}

export const AppointmentContext = createContext<AppointmentContextType>({
  tab: "new",
  appointment: undefined,
  loading: false,
  formValues: {},
  form: undefined,
  setTab: () => {},
  validateVacancies: async () => {},
  validateVacancy: async () => {},
  refetch: async () => {},
});

export const useAppointment = () => {
  return useContext(AppointmentContext);
};

export const AppointmentProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const {
    eventInfo,
    storeId,
    openDrawer,
    setOpenDrawer,
    setOpenCustomerDrawer,
  } = useCalendar();
  const [form] = Form.useForm();
  const formValues = Form.useWatch([], form);
  const {
    validateVacancies,
    validateVacancy,
    isModalVisible,
    handleOk,
    handleCancel,
    errorData,
  } = useValidateVacancies({ form });

  const {
    data: appointment,
    loading,
    refetch,
  } = useGetAppointmentQuery({
    variables: {
      id: parseInt(eventInfo?.event?.id, 10),
    },
    skip: !parseInt(eventInfo?.event?.id, 10),
  });

  const [tab, setTab] = useState<"new" | "blocker">(
    eventInfo?.event?.id &&
      appointment?.appointment?.appointmentStatus === AppointmentStatus.Absent
      ? "blocker"
      : "new",
  );

  useEffect(() => {
    setTab(
      appointment?.appointment?.id &&
        appointment?.appointment?.appointmentStatus === AppointmentStatus.Absent
        ? "blocker"
        : "new",
    );
  }, [appointment]);

  useEffect(() => {
    if (!openDrawer) {
      setOpenCustomerDrawer(null);
    }
  }, [openDrawer]);

  const setEventDatesInForm = useCallback(async () => {
    if (!eventInfo?.start) return;
    const dateFrom = dayjs(eventInfo?.start)
      ? dayjs(eventInfo?.start)
      : undefined;
    form?.setFieldsValue({
      ...form?.getFieldsValue(),
      dateFrom: dayjs(eventInfo?.start) ? dayjs(eventInfo?.start) : undefined,
      dateUntil: dayjs(eventInfo?.start)
        ? dayjs(eventInfo?.start).add(
            getProductsTotalDuration(form.getFieldValue("products")) || 15,
            "minute",
          )
        : undefined,
    });
    if (!loading && dateFrom) {
      await validateVacancies({
        values: form?.getFieldsValue(),
        appointmentId: appointment?.appointment?.id,
        index: undefined,
        force: true,
        restore: true,
      });
    }
  }, [eventInfo, appointment]);

  const setNewDateInForm = useCallback(() => {
    form?.setFieldsValue({
      dateFrom: dayjs(eventInfo?.start) ? dayjs(eventInfo?.start) : undefined,
      dateUntil: dayjs(eventInfo?.end)
        ? dayjs(eventInfo?.end).add(15, "minutes")
        : undefined,
      storeId,
      customerIds: [],
      internalNote: "",
      message: "",
      bookedPrice: "0",
      notifyForEarlierAppointment: false,
      usedCouponIds: [],
      products: [],
    });
  }, [eventInfo]);

  const setInitialFormValues = () => {
    form?.setFieldsValue({
      dateFrom: appointment?.appointment.dateFrom,
      dateUntil: appointment?.appointment.dateUntil,
      storeId: appointment?.appointment?.store?.id,
      storeIds: appointment?.appointment?.store?.id
        ? [appointment?.appointment?.store?.id]
        : [],
      customerIds:
        appointment?.appointment?.customers?.map((c: any) => c.id) || [],
      internalNote: appointment?.appointment?.internalNote || "",
      message: appointment?.appointment?.message || "",
      bookedPrice: appointment?.appointment?.bookedPrice || "0",
      practitionerIds: appointment?.appointment?.practitioners?.map(
        (p: any) => p.id,
      ),
      notifyForEarlierAppointment:
        appointment?.appointment.notifyForEarlierAppointment || false,
      products:
        (appointment?.appointment?.products || []).map(
          (appointmentProduct: any, index: number) => ({
            key: index,
            id: appointmentProduct.id,
            productId: appointmentProduct.product?.id,
            variationId: appointmentProduct.variation?.id,
            requiredResources: appointmentProduct.requiredResources || [],
            bookedPrice: Number(appointmentProduct.bookedPrice),
            name: appointmentProduct?.name || "",
            totalDuration: appointmentProduct.totalDuration,
            usedCouponIds:
              appointmentProduct?.usedCoupons?.map(
                (coupon: any) => coupon.id,
              ) || [],
            packageId: appointmentProduct?.package?.id,
            allocations: appointmentProduct.allocations,
          }),
        ) || [],

      usedCouponIds:
        Array.from(
          new Set(
            appointment?.appointment?.products
              ?.flatMap(
                (product: any) =>
                  product.usedCoupons?.map((coupon: any) => coupon?.id),
              )
              .filter((id: any) => id != null),
          ),
        ) || [],
      notifyCustomer: true,
    });
    appointment?.appointment?.customers?.forEach((c) => {
      if (c && c.note) {
        confirm({
          title: `Kundennotiz:`,
          content: c?.note,
          okText: "OK",
        });
      }
    });
  };

  useEffect(() => {
    (async () => {
      if (openDrawer) return;
      if (eventInfo?.event) {
        await setEventDatesInForm();
      } else if (!eventInfo?.event && !appointment) {
        setNewDateInForm();
      } else {
        setInitialFormValues();
      }

      if (eventInfo) {
        setOpenDrawer(true);
      }
    })();
  }, [eventInfo]);

  useEffect(() => {
    setInitialFormValues();
  }, [appointment]);

  const value = useMemo(
    () => ({
      tab,
      setTab,
      appointment: appointment?.appointment,
      loading,
      formValues,
      form,
      validateVacancy,
      validateVacancies,
      refetch,
    }),
    [formValues, appointment, loading, tab, form],
  );
  return (
    <AppointmentContext.Provider value={value}>
      <Modal
        title={
          <Flex gap={10} align="center">
            <WarningOutlined
              style={{
                color:
                  errorData?.code === "STORE_MISSES_RESOURCE"
                    ? "#FF4D4F"
                    : "#FAAD14",
              }}
            />
            <Typography.Title
              level={5}
              style={{
                marginBottom: 0,
              }}
            >
              {errorData?.code === "STORE_MISSES_RESOURCE"
                ? "Der Filiale fehlt folgende Ressource"
                : errorData?.code === "UNAVAILABLE_RESOURCES"
                ? "Die folgenden Ressourcen sind nicht verfügbar"
                : `Unerwarteter Fehler. Bitte melden: ${errorData}`}
            </Typography.Title>
          </Flex>
        }
        okText="Termin erzwingen"
        open={isModalVisible}
        okButtonProps={{
          disabled: errorData?.code === "STORE_MISSES_RESOURCE",
        }}
        onOk={handleOk}
        cancelText="Abbrechen"
        onCancel={handleCancel}
      >
        <VacanciesErrorContent errorData={errorData} />
      </Modal>
      {children}
    </AppointmentContext.Provider>
  );
};
