import React, { useEffect, useMemo } from "react";
import { Collapse, Descriptions, Skeleton, Space, Typography } from "antd";
import { where } from "@3ts/react-ant-crud";
import dayjs from "dayjs";
import {
  PractitionerCategoryFragment,
  ResourceType,
  RoomCategoryFragment,
  useGetEquipmentCategoriesLazyQuery,
  useGetEquipmentLazyQuery,
  useGetPractitionerCategoriesLazyQuery,
  useGetPractitionerLazyQuery,
  useGetProductCategoriesLazyQuery,
  useGetRoomCategoriesLazyQuery,
  useGetRoomLazyQuery,
} from "../../../../graphql/schema";

interface Props {
  errorData: any;
}

interface ContentLabelProps {
  type: ResourceType;
  property: string;
  value: number[];
  operator?: string;
}

const ContentLabel = ({
  type,
  value,
  property,
  operator,
}: ContentLabelProps) => {
  const [
    fetchPractitionerCategories,
    {
      data: practitionerCategoriesData,
      loading: loadingPractitionerCategories,
    },
  ] = useGetPractitionerCategoriesLazyQuery();
  const [
    fetchPractitioner,
    { data: practitionerData, loading: loadingPractitionerData },
  ] = useGetPractitionerLazyQuery();

  const [fetchRoom, { data: roomData, loading: loadingRoomData }] =
    useGetRoomLazyQuery();
  const [
    fetchProductCategories,
    { data: productCategoriesData, loading: loadingProductCategoriesData },
  ] = useGetProductCategoriesLazyQuery();
  const [
    fetchRoomCategories,
    { data: roomCategoriesData, loading: loadingRoomCategoriesData },
  ] = useGetRoomCategoriesLazyQuery();

  const [
    fetchEquipment,
    { data: equipmentData, loading: loadingEquipmentData },
  ] = useGetEquipmentLazyQuery();
  const [
    fetchEquipmentCategories,
    { data: equipmentCategoriesData, loading: loadingEquipmentCategoriesData },
  ] = useGetEquipmentCategoriesLazyQuery();

  useEffect(() => {
    if (type === ResourceType.Practitioner) {
      if (property === "categoryId")
        fetchPractitionerCategories({
          variables: {
            options: {
              ...where<PractitionerCategoryFragment>({
                id: {
                  _in: value,
                },
              }),
            },
          },
        });
      if (property === "productCategoryId")
        fetchProductCategories({
          variables: {
            options: {
              ...where<RoomCategoryFragment>({
                id: {
                  _in: value,
                },
              }),
            },
          },
        });
      if (property === "id")
        fetchPractitioner({
          variables: {
            id: value[0]?.toString() ?? Number?.MIN_SAFE_INTEGER,
          },
        });
    } else if (type === ResourceType.Room) {
      if (property === "categoryId")
        fetchRoomCategories({
          variables: {
            options: {
              ...where<RoomCategoryFragment>({
                id: {
                  _in: value,
                },
              }),
            },
          },
        });
      if (property === "id")
        fetchRoom({
          variables: {
            id: value[0]?.toString() ?? Number?.MIN_SAFE_INTEGER,
          },
        });
    } else if (type === ResourceType.Equipment) {
      if (property === "categoryId")
        fetchEquipmentCategories({
          variables: {
            options: {
              ...where<RoomCategoryFragment>({
                id: {
                  _in: value,
                },
              }),
            },
          },
        });
      if (property === "id")
        fetchEquipment({
          variables: {
            id: value[0]?.toString() ?? Number?.MIN_SAFE_INTEGER,
          },
        });
    }
  }, [type, value]);

  if (
    loadingPractitionerCategories ||
    loadingRoomCategoriesData ||
    loadingPractitionerData ||
    loadingRoomData ||
    loadingProductCategoriesData ||
    loadingEquipmentData ||
    loadingEquipmentCategoriesData
  ) {
    return <Skeleton active />;
  }

  if (type === ResourceType.Practitioner) {
    switch (property) {
      case "categoryId":
        return (
          <Descriptions
            column={{ xs: 1 }}
            items={[
              {
                label: "Kategorie",
                children:
                  practitionerCategoriesData?.practitionerCategories?.items?.map(
                    (item: any, index: number) => (
                      <Typography.Text key={item?.id}>
                        {index ? " / " : ""}
                        {item?.name}
                      </Typography.Text>
                    ),
                  ),
              },
            ]}
          />
        );
      case "productCategoryId":
        return (
          <Descriptions
            column={{ xs: 1 }}
            items={[
              {
                label: "Leistungskategorie",
                children: productCategoriesData?.productCategories?.items?.map(
                  (item: any, index: number) => (
                    <Typography.Text key={item?.id}>
                      {index ? " / " : ""}
                      {item?.name}
                    </Typography.Text>
                  ),
                ),
              },
            ]}
          />
        );
      case "id":
        return (
          <Descriptions
            column={{ xs: 1 }}
            items={[
              {
                label: operator === "NOT IN" ? "Ausgeschlossen" : "Behandler",
                children: (
                  <Typography.Text key={practitionerData?.practitioner?.id}>
                    {practitionerData?.practitioner?.title}{" "}
                    {practitionerData?.practitioner?.firstName}{" "}
                    {practitionerData?.practitioner?.lastName}
                  </Typography.Text>
                ),
              },
            ]}
          />
        );
      default:
        return null;
    }
  } else if (type === ResourceType.Room) {
    switch (property) {
      case "categoryId":
        return (
          <Descriptions
            column={{ xs: 1 }}
            items={[
              {
                label: "Kategorie",
                children: roomCategoriesData?.roomCategories?.items?.map(
                  (item: any, index: number) => (
                    <Typography.Text key={item?.id}>
                      {index ? " / " : ""}
                      {item?.name}
                    </Typography.Text>
                  ),
                ),
              },
            ]}
          />
        );
      case "id":
        return (
          <Descriptions
            column={{ xs: 1 }}
            items={[
              {
                label: "Raum",
                children: (
                  <Typography.Text key={roomData?.room?.id}>
                    {roomData?.room?.name}
                  </Typography.Text>
                ),
              },
            ]}
          />
        );
      default:
        return null;
    }
  } else if (type === ResourceType.Equipment) {
    switch (property) {
      case "categoryId":
        return (
          <Descriptions
            column={{ xs: 1 }}
            items={[
              {
                label: "Kategorie",
                children:
                  equipmentCategoriesData?.equipmentCategories?.items?.map(
                    (item: any, index: number) => (
                      <Typography.Text key={item?.id}>
                        {index ? " / " : ""}
                        {item?.name}
                      </Typography.Text>
                    ),
                  ),
              },
            ]}
          />
        );
      case "id":
        return (
          <Descriptions
            column={{ xs: 1 }}
            items={[
              {
                label: "Gerät",
                children: (
                  <Typography.Text key={equipmentData?.equipment?.id}>
                    {equipmentData?.equipment?.name}
                  </Typography.Text>
                ),
              },
            ]}
          />
        );
      default:
        return null;
    }
  } else {
    return null;
  }
};

const VacanciesErrorContent = ({ errorData }: Props) => {
  const code = errorData?.code;

  const getCollapseItemLabel = (resource: any) => {
    const { resourceType } = resource.resourceConfig;
    let resourceName = "Behandler";
    if (resourceType === ResourceType.Room) resourceName = "Raum";
    if (resourceType === ResourceType.Equipment) resourceName = "Gerät";
    if (code === "STORE_MISSES_RESOURCE") {
      return <Typography.Text>{`${resourceName}`}</Typography.Text>;
    }
    return (
      <Typography.Text>
        {`${resourceName} zwischen ${dayjs(resource?.start)
          .tz()
          .format("HH:mm")} Uhr und ${dayjs(resource.end)
          .tz()
          .format("HH:mm")} Uhr`}
      </Typography.Text>
    );
  };
  const data = useMemo(() => {
    return code === "STORE_MISSES_RESOURCE"
      ? [
          {
            key: "STORE_MISSES_RESOURCE",
            label: getCollapseItemLabel(errorData),
            children: (
              <Space direction="vertical">
                {errorData.resourceConfig.resourceRequirements.map(
                  (resourceRequirement: any) => (
                    <ContentLabel
                      key={`${"STORE_MISSES_RESOURCE"}-${
                        resourceRequirement.property
                      }`}
                      type={errorData.resourceConfig.resourceType}
                      property={resourceRequirement.property}
                      value={resourceRequirement.value}
                      operator={resourceRequirement.operator}
                    />
                  ),
                )}
              </Space>
            ),
          },
        ]
      : errorData?.resources?.map((resource: any, i: number) => ({
          key: `${resource.blocker}-${i}`,
          label: getCollapseItemLabel(resource),
          children: (
            <Space direction="vertical">
              <Typography.Text>Anforderungen:</Typography.Text>
              {resource.resourceConfig.resourceRequirements.map(
                (resourceRequirement: any, j: number) => (
                  <ContentLabel
                    key={`${resource.blocker}-${resourceRequirement.property}-${i}-${j}`}
                    type={resource.resourceConfig.resourceType}
                    property={resourceRequirement.property}
                    value={resourceRequirement.value}
                    operator={resourceRequirement.operator}
                  />
                ),
              )}
            </Space>
          ),
        }));
  }, [errorData]);

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
      }}
    >
      <Collapse items={data} ghost />
    </Space>
  );
};

export default VacanciesErrorContent;
