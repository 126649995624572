import React, { useCallback, useEffect, useMemo, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useLocation } from "react-router-dom";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import listPlugin from "@fullcalendar/list";
import { EventClickArg, EventContentArg } from "@fullcalendar/core";
import { Col, Modal, Row, Typography } from "antd";
import { where } from "@3ts/react-ant-crud";
import dayjs from "dayjs";
import { plugin as dayjsTimeZonePlugin } from "fullcalendar-plugin-dayjs-timezone";
import {
  PractitionerFragment,
  ResourceType,
  useGetCalendarAppointmentsBetweenDatesLazyQuery,
  useGetCalendarStoreLazyQuery,
  useGetPractitionersAvailabilitiesByStoreIdLazyQuery,
  useGetPractitionersLazyQuery,
  useGetResourceAllocationsBetweenDatesForTypeLazyQuery,
} from "../../../../graphql/schema";
import styles from "./Calendar.module.scss";
import practitionerColors from "./PractitionerColors";
import CalendarTitle from "./CalendarTitle";
import DatePickerOptions from "./toolbar/DatePickerOptions";
import FilterOptions from "./toolbar/FilterOptions";
import ViewOptions from "./toolbar/ViewOptions";
import getBusinessHours from "../../../../helper/common/getBusinessHours";
import EventPopover from "./popover/EventPopover";
import { useCalendar } from "./CalendarContext";
import { AppointmentProvider } from "../context/AppointmentContext";
import AppointmentDrawer from "../drawer/AppointmentDrawer";
import CustomerDrawer from "../drawer/CustomerDrawer";
import { getEventsByAllocations, getEventsByAppointments } from "./events";

const { confirm } = Modal;

const CalendarForm = () => {
  const {
    eventInfo,
    practitionerIds,
    appointmentStatus,
    initialDate,
    initialView,
    setEventInfo,
    viewDates,
    storeId,
    openDrawer,
    setOpenDrawer,
  } = useCalendar();
  const { openCustomerDrawer } = useCalendar();
  const calenderRef = React.useRef<any>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [fetchPractitioners, { data: practitionersData }] =
    useGetPractitionersLazyQuery({
      fetchPolicy: "no-cache",
    });

  const [fetchAppointments, { data: appointmentsData }] =
    useGetCalendarAppointmentsBetweenDatesLazyQuery({
      fetchPolicy: "no-cache",
    });

  const [fetchAllocations, { data: allocationsData }] =
    useGetResourceAllocationsBetweenDatesForTypeLazyQuery({
      fetchPolicy: "no-cache",
    });

  const [fetchStore, { data: storeData }] = useGetCalendarStoreLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [fetchAvailabilities, { data: availabilitiesData }] =
    useGetPractitionersAvailabilitiesByStoreIdLazyQuery({
      fetchPolicy: "no-cache",
    });

  // State to manage practitioner colors
  const [practitionerColorMap, setPractitionerColorMap] = useState<{
    [key: number]: string;
  }>({});

  // Fetch data when necessary
  useEffect(() => {
    if (storeId) {
      fetchPractitioners({
        variables: {
          options: {
            itemsPerPage: 50,
            ...where<PractitionerFragment>({
              "stores.id": storeId,
            }),
          },
        },
      });

      fetchStore({
        variables: {
          id: storeId.toString(),
        },
      });

      fetchAppointments({
        variables: {
          start: viewDates.start.toISOString(),
          end: viewDates.end.toISOString(),
          storeId,
        },
      });

      fetchAvailabilities({
        variables: {
          start: viewDates.start.toISOString(),
          end: viewDates.end.toISOString(),
          storeId,
        },
      });
    }
  }, [storeId, viewDates]);

  useEffect(() => {
    if (
      calenderRef.current &&
      calenderRef.current.getApi().view.type === "resourceTimeGridDay"
    ) {
      fetchAllocations({
        variables: {
          start: viewDates.start.toISOString(),
          end: viewDates.end.toISOString(),
          resourceType: ResourceType.Practitioner,
        },
      });
    }
  }, [calenderRef, viewDates]);

  // Update practitioner colors
  useEffect(() => {
    if (practitionersData?.practitioners.items?.length) {
      const newValues: { [key: number]: string } = {};
      practitionersData.practitioners.items.forEach((item, index) => {
        newValues[item.id] = practitionerColors[index] || "white";
      });
      setPractitionerColorMap(newValues);
    }
  }, [practitionersData]);

  const resources = useMemo(() => {
    const getPractitionersInScope = (
      practitionersList: PractitionerFragment[] | undefined,
    ) => {
      if (!practitionersList) return [];
      return practitionersList.filter((item) =>
        practitionerIds.length ? practitionerIds.includes(item.id) : true,
      );
    };

    const practitionersInScope = getPractitionersInScope(
      practitionersData?.practitioners?.items,
    );

    const getPractitionerAvailabilities = (
      availabilities: any[] | undefined,
      practitioner: PractitionerFragment,
    ) => {
      if (!availabilities?.length) return [];
      return availabilities.filter(
        (av) => av.practitioner?.id === practitioner.id,
      );
    };

    return [
      {
        id: "0",
        title: "Nicht zugeordnet",
        businessHours: [],
      },
      ...practitionersInScope.map((item) => {
        const businessHours =
          getPractitionerAvailabilities(
            availabilitiesData?.practitionersAvailabilitiesByStoreId,
            item,
          ).map((av) => {
            return {
              daysOfWeek: [dayjs.tz(av.start).day()],
              startTime: dayjs.tz(new Date(av.start)).format("HH:mm"),
              endTime: dayjs.tz(new Date(av.end)).format("HH:mm"),
            };
          }) || [];

        return {
          id: item.id.toString(),
          title: `${item.title} ${item.firstName} ${item.lastName}`,
          businessHours: businessHours.length
            ? businessHours
            : [
                {
                  daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
                  startTime: "00:00",
                  endTime: "00:00",
                },
              ],
        };
      }),
    ];
  }, [
    practitionersData,
    practitionerIds,
    availabilitiesData,
    appointmentStatus,
  ]);

  const handleOpenAppointment = useCallback(
    (appointmentId: number, start: Date) => {
      if (appointmentId && start) {
        calenderRef.current.getApi().gotoDate(start);
        setEventInfo({
          event: {
            id: appointmentId,
          },
        });
        setOpenDrawer(true);
      }
    },
    [setEventInfo, setOpenDrawer],
  );

  useEffect(() => {
    if (location.state) {
      const appointmentId = location?.state?.id;
      const start = location?.state?.date;
      if (appointmentId && start) {
        handleOpenAppointment(appointmentId, new Date(start));
      }
    }
    if (params) {
      const appointmentId = params.get("id");
      const start = params.get("date");
      if (appointmentId && start) {
        handleOpenAppointment(Number(appointmentId), new Date(start));
      }
    }
  }, [location, params, handleOpenAppointment]);

  const getHeaderDate = useCallback(() => {
    if (calenderRef.current) {
      const viewType = calenderRef.current.getApi().view.type;
      const startDate = dayjs(viewDates.start).tz();
      if (viewType === "timeGridThreeDays") {
        return `${startDate.format("DD.MM.YYYY")} - ${startDate
          .add(2, "days")
          .format("DD.MM.YYYY")}`;
      }
      if (viewType === "timeGridWeek") {
        return `${startDate.format("DD.MM.YYYY")} - ${startDate
          .add(6, "days")
          .format("DD.MM.YYYY")}`;
      }
      return startDate.format("DD.MM.YYYY");
    }
    return "";
  }, [viewDates]);

  useEffect(() => {
    if (calenderRef.current) {
      calenderRef.current.getApi().gotoDate(initialDate);
      calenderRef.current.getApi().changeView(initialView);
    }
  }, [initialDate, initialView]);

  const businessHours = useMemo(() => {
    if (initialView === "resourceTimeGridDay") return [];
    return getBusinessHours({
      store: storeData?.store,
    });
  }, [storeData, initialView]);

  const isOutsideBusinessHours = useCallback(
    (date: Date) => {
      const timezoned = dayjs(date).tz();

      const startDay = timezoned.day();
      const startHour = timezoned.hour();
      const startMinutes = timezoned.minute();

      return businessHours.some((item: any) => {
        if (item.daysOfWeek.includes(startDay)) {
          const [startHourStr, startMinuteStr] =
            item?.startTime?.split(":") || [];
          const [endHourStr, endMinuteStr] = item?.endTime?.split(":") || [];

          const slotStartTime = new Date().setHours(startHour, startMinutes);
          const businessStartTime = new Date().setHours(
            Number(startHourStr || 0),
            Number(startMinuteStr || 0),
          );
          const businessEndTime = new Date().setHours(
            Number(endHourStr || 0),
            Number(endMinuteStr || 0),
          );

          if (
            (startHourStr && slotStartTime < businessStartTime) ||
            (endHourStr && slotStartTime >= businessEndTime)
          ) {
            return true;
          }
        }
        return false;
      });
    },
    [businessHours],
  );

  const handleSelectSlot = useCallback(
    (selectInfo: any) => {
      if (isOutsideBusinessHours(selectInfo.start)) {
        confirm({
          type: "warning",
          title: "Achtung - Termin ist außerhalb der Öffnungszeiten",
          content:
            "Möchten Sie den Termin außerhalb der Öffnungszeiten trotzdem anlegen?",
          onOk() {
            setEventInfo(
              eventInfo
                ? { ...selectInfo, event: eventInfo.event }
                : selectInfo,
            );
          },
        });
      } else {
        setEventInfo(
          eventInfo ? { ...selectInfo, event: eventInfo.event } : selectInfo,
        );
      }
    },
    [setEventInfo, isOutsideBusinessHours, eventInfo],
  );

  const events = useMemo(() => {
    if (
      calenderRef.current &&
      calenderRef.current.getApi().view.type === "resourceTimeGridDay"
    ) {
      return getEventsByAllocations(
        allocationsData?.resourceAllocationsBetweenDatesForType,
        appointmentsData?.appointmentsBetweenDates,
      );
    }
    return getEventsByAppointments(
      appointmentsData?.appointmentsBetweenDates,
      appointmentStatus,
      practitionerIds,
      storeId,
    );
  }, [
    allocationsData,
    appointmentsData,
    appointmentStatus,
    practitionerIds,
    storeId,
  ]);

  return (
    <Row>
      <Col span={24}>
        <CalendarTitle />
        <Row>
          <Col span={openDrawer ? 16 : 24}>
            <Row
              style={{
                width: "100%",
                paddingTop: 10,
                paddingBottom: 10,
              }}
            >
              <DatePickerOptions
                drawerVisible={openDrawer}
                calenderRef={calenderRef}
              />

              <Col span={openDrawer ? 0 : 8}>
                <Typography.Title
                  level={2}
                  style={{
                    margin: 0,
                    textAlign: "center",
                  }}
                >
                  {getHeaderDate()}
                </Typography.Title>
              </Col>

              <Col
                span={openDrawer ? 12 : 8}
                style={{
                  textAlign: "end",
                }}
              >
                <FilterOptions
                  openDrawer={openDrawer}
                  handleOpenAppointment={handleOpenAppointment}
                />

                <ViewOptions calenderRef={calenderRef} />
              </Col>
            </Row>
            <FullCalendar
              timeZone="Europe/Berlin"
              schedulerLicenseKey="0862586236-fcs-1713283190"
              firstDay={1}
              height={window.outerHeight - 200}
              ref={calenderRef}
              viewClassNames={[styles.calendar, styles.fcDaygridDayEvents]}
              dayHeaderClassNames={[styles.dayHeader]}
              dayCellClassNames={[styles.dayCell]}
              resources={resources}
              eventClassNames={[styles.event]}
              slotLaneClassNames={[styles.slotLane]}
              plugins={[
                timeGridPlugin,
                dayGridPlugin,
                listPlugin,
                interactionPlugin,
                resourceTimeGridPlugin,
                dayjsTimeZonePlugin,
              ]}
              initialView={initialView}
              initialDate={initialDate}
              select={handleSelectSlot}
              headerToolbar={false}
              titleFormat={{
                year: "numeric",
                month: "long",
                day: "numeric",
              }}
              slotMinTime="06:00:00"
              slotMaxTime="22:00:00"
              allDayText="Ganz&shy;tägig"
              allDayClassNames={styles.allDay}
              nowIndicator
              eventContent={(eventContent: EventContentArg) => (
                <EventPopover info={eventContent} />
              )}
              locale="de"
              views={{
                timeGridThreeDays: {
                  type: "timeGrid",
                  duration: { days: 3 },
                },
              }}
              buttonText={{
                today: "Heute",
                month: "Monat",
                week: "Woche",
                day: "Tag",
                resourceTimeGridDay: "Tag",
                listWeek: "Liste",
                timeGridThreeDays: "3 Tage",
              }}
              eventClick={(info: EventClickArg) => {
                if (!openDrawer) {
                  setEventInfo(info);
                }
              }}
              events={events}
              businessHours={businessHours}
              slotDuration="00:15:00"
              slotLabelFormat={{
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
                week: "short",
              }}
              selectable
              eventResourceEditable
            />
          </Col>
          <Col span={openDrawer ? 8 : 0}>
            <AppointmentProvider>
              <AppointmentDrawer
                open={openDrawer && !!storeId}
                onClose={() => {
                  window.history.replaceState({}, "");
                  setOpenDrawer(false);
                  setEventInfo(undefined);
                }}
              />
              <CustomerDrawer id={openCustomerDrawer} />
            </AppointmentProvider>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CalendarForm;
