import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useCallback, useState } from "react";
import { addMinutes } from "date-fns";

import {
  useGetCalendarCouponLazyQuery,
  useVerifyCouponsMutation,
} from "../../../../graphql/schema";
import Footer from "./Footer";
import CreateCustomerModal from "./CreateCustomerModal";
import getProductsTotalDuration from "../../../../helper/common/getProductsTotalDuration";
import formItemProps from "../../../../helper/form/formItemProps";
import PractitionersSelect from "../../../practitioners/PractitionersSelect";
import CustomersSelect from "../../../customer/CustomersSelect";
import AppointmentProductsList from "../../appointmentProducts/AppointmentProductsList";
import CouponsSelect from "../../../coupons/CouponsSelect";
import StoreSelect from "../../../store/StoreSelect";
import Loading from "../../../base/Loading";
import { useAppointment } from "../context/AppointmentContext";
import { useCalendar } from "../calendar/CalendarContext";
import AppointmentTab from "./AppointmentTab";
import StaticTimezoneDatePicker from "../../../staticTimezoneDatePicker/StaticTimezoneDatePicker";

interface Props {
  open: boolean;
  onClose: () => void;
}

const colSmall = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
};

const AppointmentDrawer = ({ open, onClose }: Props) => {
  const { appointment, tab, validateVacancies, loading, formValues, form } =
    useAppointment();
  const { eventInfo } = useCalendar();
  const [showCreateCustomer, setShowCreateCustomer] = useState(false);

  const [fetchCoupon] = useGetCalendarCouponLazyQuery();
  const [verify] = useVerifyCouponsMutation();

  const verifyCoupon = async (
    values: any,
    couponIds: number[],
  ): Promise<boolean> => {
    try {
      const couponCodes = await Promise.all(
        couponIds.map(async (couponId) => {
          const { data } = await fetchCoupon({
            variables: { id: couponId.toString() },
          });
          return data?.coupon?.code;
        }),
      ).then((codes) => codes.filter((code): code is string => !!code));

      const response = await verify({
        variables: {
          dto: {
            products: values.products.map((product: any) => ({
              productId: product.productId,
              variationId: product.variationId || null,
              price: String(product.price || "0"),
            })),
            couponCodes,
            customer: {
              id: !appointment ? values.customerIds : values.customerIds[0],
            },
          },
        },
      });

      const verifyCoupons = response?.data?.verifyCoupons;

      if (verifyCoupons && verifyCoupons.length) {
        const updatedProducts = values.products.map(
          (product: any, index: number) => {
            const verifiedCoupon = verifyCoupons[index];
            return {
              ...product,
              usedCouponIds: verifiedCoupon?.usedCoupons?.map(
                (coupon: any) => coupon.id,
              ),
              bookedPrice: product.packageId
                ? "0"
                : String(verifiedCoupon?.price || "0"),
            };
          },
        );

        const totalBookedPrice = updatedProducts.reduce(
          (acc: number, product: any) => {
            return acc + Number(product.bookedPrice || "0");
          },
          0,
        );

        form?.setFieldsValue({
          ...values,
          products: updatedProducts,
          bookedPrice: String(totalBookedPrice),
        });

        message.success("Gutschein gültig");
      } else {
        message.error("Gutschein nicht gültig");
      }
    } catch (error) {
      console.error("Error verifying coupon:", error);
      message.error("Gutschein nicht gültig");
    }

    return true;
  };

  const handleAppointmentDuration = useCallback(() => {
    const dateFrom = dayjs(form?.getFieldValue("dateFrom"));
    const appointmentProducts = form?.getFieldValue(["products"]);
    const totalDuration = getProductsTotalDuration(appointmentProducts);

    if (dateFrom) {
      form?.setFieldValue(
        "dateUntil",
        dayjs(addMinutes(dateFrom.toDate(), totalDuration)),
      );
    }
  }, []);

  if (loading) return <Loading />;

  return (
    <Drawer
      contentWrapperStyle={{
        width: "30%",
      }}
      extra={<AppointmentTab />}
      mask={false}
      footer={formValues ? <Footer tab={tab} closeDrawer={onClose} /> : null}
      placement="right"
      onClose={async () => {
        onClose();
        form?.resetFields();
      }}
      open={open}
    >
      {showCreateCustomer && (
        <CreateCustomerModal
          open={showCreateCustomer}
          onCustomerCreated={(id: number) => {
            form?.setFieldsValue({
              ...form?.getFieldsValue(),
              customerIds: [...form.getFieldValue("customerIds"), id],
            });
            setShowCreateCustomer(false);
          }}
          onClose={() => setShowCreateCustomer(false)}
        />
      )}
      {(!appointment?.id && eventInfo?.event?.id) || loading ? (
        <Loading />
      ) : (
        <Form layout="vertical" form={form} initialValues={formValues}>
          <Row gutter={10}>
            <Col>
              <Form.Item {...formItemProps("dateFrom", "Datum und Beginn")}>
                <StaticTimezoneDatePicker
                  allowClear={false}
                  format="DD.MM.YYYY HH:mm"
                  style={{ width: "100%" }}
                  showTime
                  minuteStep={5}
                  onChange={async () => {
                    handleAppointmentDuration();
                    await validateVacancies({
                      values: form?.getFieldsValue(),
                      appointmentId: appointment?.id,
                      force: false,
                      restore: false,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                {...formItemProps("dateUntil", "Ende")}
                tooltip={
                  tab !== "blocker"
                    ? "Das Terminende errechnet sich automatisch aus den gebuchten Leistungen"
                    : ""
                }
              >
                <StaticTimezoneDatePicker
                  allowClear={false}
                  format={tab !== "blocker" ? "HH:mm" : "DD.MM.YYYY HH:mm"}
                  style={{ width: "100%" }}
                  showTime
                  disabled={tab !== "blocker"}
                />
              </Form.Item>
            </Col>
          </Row>
          {tab === "blocker" && (
            <Col {...colSmall}>
              <Form.Item
                {...formItemProps("practitionerIds", "Behandler", true)}
              >
                <PractitionersSelect />
              </Form.Item>
            </Col>
          )}
          <Row
            hidden={tab !== "new"}
            style={{
              position: "relative",
            }}
          >
            <Button
              type="link"
              disabled={!!appointment?.id}
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                color: "#1890ff",
                fontSize: 14,
                zIndex: 999,
              }}
              onClick={() => setShowCreateCustomer(true)}
            >
              Kunde erstellen
            </Button>
            <Col {...colSmall}>
              <Form.Item {...formItemProps("customerIds", "Kunden", true)}>
                <CustomersSelect disabled={!!appointment?.id} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col {...colSmall}>
              <Form.Item
                {...formItemProps("internalNote", "Interne Notiz", false)}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row
            hidden={tab !== "new"}
            style={{
              position: "relative",
            }}
          >
            <Col {...colSmall}>
              <Form.Item
                {...formItemProps("message", "Nachricht", false)}
                style={{ marginBottom: 0 }}
              >
                <Input.TextArea />
              </Form.Item>
            </Col>
            <Col
              {...colSmall}
              style={{
                marginTop: 10,
              }}
            >
              <Typography.Text>Gebuchte Leistungen</Typography.Text>
              <Form.Item>
                <AppointmentProductsList
                  id={parseInt(eventInfo?.event?.id, 10)}
                />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item {...formItemProps("usedCouponIds", "Gutscheine")}>
                <CouponsSelect
                  onChange={async (couponIds: number[]) => {
                    await verifyCoupon(form?.getFieldsValue(), couponIds);
                  }}
                />
              </Form.Item>
            </Col>
            <Col {...colSmall}>
              <Form.Item {...formItemProps("storeId", "Filiale")}>
                <StoreSelect />
              </Form.Item>
            </Col>
            {/*            <Col {...colSmall}>
              <Form.Item
                {...formItemProps("notifyForEarlierAppointment", "", false)}
                valuePropName="checked"
              >
                <Checkbox>Bei früherem Termin benachrichtigen</Checkbox>
              </Form.Item>
            </Col> */}
            <Col {...colSmall}>
              <Form.Item
                tooltip="Gesamtpreis der gebuchten Leistungen. Der tatsächliche Preis kann durch die verwendeten Gutscheine abweichen."
                {...formItemProps("bookedPrice", "Gesamtpreis", false)}
              >
                <InputNumber
                  disabled
                  style={{ width: "100%" }}
                  precision={2}
                  parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Drawer>
  );
};

export default AppointmentDrawer;
